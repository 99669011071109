import React, { useEffect } from 'react'
import {
  Stack,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Link,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useMachine } from '@xstate/react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import loginMachine, { events } from './login.machine'
import { useUserService } from '../../features/user/user.service'
import { useForm } from 'react-hook-form'

const Login = () => {
  const [currentState, send] = useMachine(loginMachine)
  const { isInitialized, isAuthenticated } = useUserService()

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      navigate('/dashboard')
    }
  }, [isInitialized, isAuthenticated])

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = data => {
    send({
      type: events.SUBMIT,
      payload: data,
    })
  }

  const isLoading = currentState.matches('fetching')
  const hasError = currentState.matches('error')

  return (
    <Stack flexDirection="column" maxWidth="md" spacing={5} width="100%">
      <Heading textAlign="center">Sign in to your account</Heading>
      <Text mb={1} textAlign="center">
        Don&apos;t have an account?
        <Link
          as={GatsbyLink}
          color="blue.500"
          fontWeight="bold"
          ml="2"
          to="/signup"
        >
          Create one for free
        </Link>
      </Text>
      <Stack borderWidth="1px" px={5} py={3} rounded="md">
        <FormControl
          as="form"
          isInvalid={hasError}
          my={6}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabel htmlFor="email">Email</FormLabel>
          {errors.email && (
            <Text color="red.300" fontSize="xs">
              {errors.email.message}
            </Text>
          )}
          <Input
            mb={4}
            size="lg"
            {...register('email', {
              required: 'Email is required', pattern: {
                value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email address'
              }
            })}
          />
          <FormLabel htmlFor="password">Password</FormLabel>
          {errors.password && (
            <Text color="red.300" fontSize="xs">
              {errors.password.message}
            </Text>
          )}
          <Input
            mb={4}
            size="lg"
            type="password"
            {...register('password', {
              required: 'Password is required'
            })}
          />
          <Flex alignItems="center" justifyContent="flex-end" mb={4}>
            <Link
              as={GatsbyLink}
              textDecoration="underline"
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </Flex>
          <FormErrorMessage>{currentState.context.error}</FormErrorMessage>
          <Flex justifyContent="flex-end">
            <Button mt={8} type="submit" colorScheme="teal" width="100%">
              {isLoading ? <Spinner /> : 'Sign in'}
            </Button>
          </Flex>
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default Login
