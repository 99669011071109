import { Machine, assign } from 'xstate'
import { Auth } from 'aws-amplify'
import { navigate } from '@reach/router'

// STATES

const loginStates = {
  loggedIn: 'loggedIn',
}

// EVENTS

const events = {
  SUBMIT: 'submit',
}

// SERVICES

const login = (ctx: any, evt: any) => {
  const { email, password } = evt.payload
  return Auth.signIn(email, password)
}

// OPTIONS

const loginOpts = {
  services: {
    login,
  },
  actions: {
    cacheError: assign((ctx, evt: any) => {
      console.log({ error: evt })

      return {
        error: evt.data?.message,
      }
    }),
    removeError: assign({ error: null }),
    redirectToDashboard: () => {
      navigate('/dashboard')
    },
  },
}

const loginMachine = Machine<any>(
  {
    id: 'login',
    initial: 'idle',
    context: {
      error: null,
    },
    states: {
      idle: {
        on: {
          [events.SUBMIT]: 'fetching',
        },
      },
      fetching: {
        invoke: {
          id: 'auth',
          src: 'login',
          onDone: {
            target: '#loggedIn',
          },
          onError: {
            target: 'error',
            actions: ['cacheError'],
          },
        },
      },
      error: {
        on: {
          [events.SUBMIT]: 'fetching',
        },
        exit: 'removeError',
      },

      loggedIn: {
        entry: 'redirectToDashboard',
        id: 'loggedIn',
        type: 'final',
      },
    },
  },
  loginOpts
)

export { loginStates, events }

export default loginMachine
